import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageHome from './pages/PageHome';
import PageAbout from './pages/PageAbout';
import PageJoin from './pages/PageJoin';
import PageNotFound from './pages/PageNotFound';
import { THEME_COLORS } from './utils/theme';
import { INTERNAL_LINKS } from './utils/links';
import styled from 'styled-components';
import { Nav } from './components/Nav';
import { Footer } from './components/Footer';

const Container = styled.div`
  position: absolute;
  font-weight: 500;
  color: ${THEME_COLORS.brandPink};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: fit-content;
  z-index: -3;
`;

function App() {
  return (
    <BrowserRouter>
      <Container>
        <Nav />
        <Routes>
          <Route path={INTERNAL_LINKS.HOME} element={<PageHome />} />
          <Route path={INTERNAL_LINKS.ABOUT} element={<PageAbout />} />
          <Route path={INTERNAL_LINKS.JOIN} element={<PageJoin />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Container>
    </BrowserRouter>
  );
}

export default App;
