import React from 'react';
import styled from 'styled-components';
import nolan from '../assets/teampics/nolan.jpg';
import erika from '../assets/teampics/erika.jpg';
import aanika from '../assets/teampics/aanika.jpg';
import abigal from '../assets/teampics/abigail.jpg';
import akshai from '../assets/teampics/akshai.jpg';
import annie from '../assets/teampics/annie.jpg';
import angie from '../assets/teampics/angie.jpg';
import anshul from '../assets/teampics/anshul.jpg';
import ariana from '../assets/teampics/ariana.jpg';
import caitlyn from '../assets/teampics/caitlyn.jpg';
import charlotte from '../assets/teampics/charlotte.jpg';
import janelle from '../assets/teampics/janelle.jpg';
import jocelyn from '../assets/teampics/jocelyn.jpg';
import medha from '../assets/teampics/medha.jpg';
import mira from '../assets/teampics/mira.jpg';
import nicole from '../assets/teampics/nicole.jpg';
import oorjit from '../assets/teampics/oorjit.jpg';
import ria from '../assets/teampics/ria.jpg';
import sangini from '../assets/teampics/sangini.jpg';
import sthiti from '../assets/teampics/sthiti.jpg';
import ted from '../assets/teampics/ted.jpg';
import varun from '../assets/teampics/varun.jpg';

const names = [
  ['Nolan', 'Kim', nolan],
  ['Erika', 'Ni', erika],
  ['Aanika', 'Nakra', aanika],
  ['Abigail', 'Setiwan', abigal],
  ['Akshai', 'Srinivasan', akshai],
  ['Angie', 'Niu', angie],
  ['Annie', 'Chang', annie],
  ['Anshul', 'Shah', anshul],
  ['Ariana', 'Cao', ariana],
  ['Caitlyn', 'Widjaja', caitlyn],
  ['Charlotte', 'Liu', charlotte],
  ['Janelle', 'Chan', janelle],
  ['Jocelyn', 'Velazquez', jocelyn],
  ['Medha', 'Rawat', medha],
  ['Mira', 'Lee', mira],
  ['Nicole', 'Chen', nicole],
  ['Oorjit', 'Chowdhary', oorjit],
  ['Ria', 'Patil', ria],
  ['Sangini', 'Mehta', sangini],
  ['Sthiti', 'Patnaik', sthiti],
  ['Ted', 'Guan', ted],
  ['Varun', 'Vijayababu', varun],
];

const TeamSection = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const TeamContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  @media (max-width: 991 px) {
    max-width: 728px;
  }
  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (max-width: 479px) {
    max-width: none;
  }
`;

const TeamTitleContainer = styled.div`
  margin-bottom: 30px;
  padding: 10px;
  // font-family: 'Work Sans',sans-serif;
  color: #434343;
  font-size: 35px;
  line-height: 35px;
  font-weight: 700;
  text-align: center;
`;

const TeamGrid = styled.div`
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: ;
  @media (max-width: 767px) {
    margin-right: 20px;
    margin-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-flow: row;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  @media (max-width: 479px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const HeadshotContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
`;
const HeadshotImage = styled.img`
  border-radius: 100px;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const NameContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #333;
`;

export const OurTeam = () => {
  return (
    <TeamSection id="OurTeam">
      <TeamContainer>
        <TeamTitleContainer>Our Team</TeamTitleContainer>
        <TeamGrid>
          {names.map(data => {
            const first = data[0];
            const last = data[1];
            const pic = data[2];
            return <Headshot imageSource={pic} first={first} last={last} />;
          })}
        </TeamGrid>
      </TeamContainer>
    </TeamSection>
  );
};

const Headshot = ({ imageSource, first, last }: { imageSource: string; first: string; last: string }) => {
  return (
    <HeadshotContainer>
      <HeadshotImage
        src={imageSource}
        loading="eager"
        sizes="(max-width: 479px) 36vw, (max-width: 767px) 26vw, (max-width: 991px) 198.65625px, 100.5625px"
      ></HeadshotImage>
      <NameContainer>
        {first}
        <br />
        {last}
      </NameContainer>
    </HeadshotContainer>
  );
};
