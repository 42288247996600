import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { THEME_COLORS } from '../utils/theme';
import { INTERNAL_LINKS } from '../utils/links';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  color: ${THEME_COLORS.brandPink};
  text-align: center;
`;

const MainHeading = styled.h1`
  font-size: 100px;
  margin-bottom: 70px;
`;

const SubHeading = styled.h2`
  font-size: 40px;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 20px;
  margin-bottom: 35px;
`;

const Button = styled(Link)`
  background-color: ${THEME_COLORS.brandPink};
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${THEME_COLORS.brandPink};
    opacity: 0.8;
  }
`;

export const NotFound = () => {
  return (
    <Wrapper>
      <MainHeading>404</MainHeading>
      <SubHeading>Page Not Found</SubHeading>
      <Text>Oops! The page you’re looking for doesn’t exist.</Text>
      <Button to={INTERNAL_LINKS.HOME}>Go back home</Button>
    </Wrapper>
  );
};
